<template>
	<div>
		<a-row
			type="flex"
			justify="start"
			:gutter="[32, 32]"
			style="margin-bottom: 16px;"
		>
			<a-col v-for="invoice in open_invoices" :key="invoice.due_date">
				<InvoiceCard :invoice="invoice" @detail="handleDetailInvoice" />
			</a-col>
		</a-row>
		<a-row type="flex" justify="start" :gutter="[32, 32]">
			<a-col>
				<AnnualHistoryCard :invoices="last_year_invoices" />
			</a-col>
			<a-col>
				<InvoicesHistoryCard
					:invoices="paid_invoices"
					@detail="handleDetailInvoice"
				/>
			</a-col>
		</a-row>
		<DetailInvoiceDrawer ref="detailInvoiceDrawer" />
	</div>
</template>

<script>
import {
	InvoiceCard,
	AnnualHistoryCard,
	InvoicesHistoryCard,
	DetailInvoiceDrawer,
} from './components'
import { orderByDueDate } from '@/utils/utils'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'InvoicesReport',
	components: {
		InvoiceCard,
		AnnualHistoryCard,
		InvoicesHistoryCard,
		DetailInvoiceDrawer,
	},
	data() {
		return {
			invoices: [],
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		paid_invoices: function () {
			return (
				this.invoices
					.filter((invoice) => invoice.payment_date !== null)
					.sort(orderByDueDate) || []
			)
		},
		last_year_invoices: function () {
			return [...this.invoices].slice(0, 12)
		},
		open_invoices: function () {
			return this.invoices
				.filter((invoice) => invoice.payment_date === null)
				.sort(orderByDueDate)
		},
	},
	beforeMount() {
		if (this.isAuthorized && this.isMerchantActive) this.getData()
	},
	methods: {
		...mapActions('merchants', ['getInvoices']),
		async getData() {
			try {
				this.loading = true

				const payload = {
					merchantId: this.selectedMerchantId,
					year: this.moment().format('YYYY'),
				}

				this.invoices = await this.getInvoices(payload)
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
		handleDetailInvoice(due_date) {
			const invoice = this.invoices.find(
				(invoice) => invoice.due_date === due_date
			)

			this.$refs.detailInvoiceDrawer.setShowDrawer(invoice)
		},
	},
}
</script>
